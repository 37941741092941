.packsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 10px;
  margin-bottom: 10px;

  // add media query to make it responsive
  @media (max-width: 1070px) {
    // justify-content: center;
  }
}
