.app {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}
.login_button {
    width: 22rem;

    img {
        height: 20px;
        margin-right: 5px;
    }
}
