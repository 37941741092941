.numberCard {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #999;
    margin-right: 8px;
}